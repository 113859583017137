body {
  background-color: #2e3443 !important; /* to match bgColor in Theme.ts */
  margin: 0;
}

.homepage-image {
  max-width: 66.6%;
  height: auto;
}

.image-container {
  width: 100%;
}
